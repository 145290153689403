import mkg_gmk_godspeed from "./usercolorways7/mkg_gmk_godspeed.json";
import mkg_gmk_mudbeam from "./usercolorways7/mkg_gmk_mudbeam.json";
import mkg_gmk_japanese_desko from "./usercolorways7/mkg_gmk_japanese_desko.json";
import mkg_gmk_futurefunk from "./usercolorways7/mkg_gmk_futurefunk.json";
import mkg_gmk_yuru from "./usercolorways7/mkg_gmk_yuru.json";
import mkg_gmk_delta from "./usercolorways7/mkg_gmk_delta.json";
import mkg_gmk_a from "./usercolorways7/mkg_gmk_a.json";

export const COLORWAYS_NEW = {
    mkg_gmk_godspeed: mkg_gmk_godspeed,
    mkg_gmk_mudbeam: mkg_gmk_mudbeam,
    mkg_gmk_japanese_desko: mkg_gmk_japanese_desko,
    mkg_gmk_futurefunk: mkg_gmk_futurefunk,
    mkg_gmk_yuru: mkg_gmk_yuru,
    mkg_gmk_delta: mkg_gmk_delta,
    mkg_gmk_a: mkg_gmk_a
};