import mkg_ntd from "./usercolorways1/colorway_mkg_ntd.json";
import mkg_m1 from "./usercolorways1/colorway_mkg_masterpiece.json";
import mkg_m2 from "./usercolorways1/colorway_mkg_kaiju.json";
import mkg_m3 from "./usercolorways1/colorway_mkg_sang.json";
import mkg_m4 from "./usercolorways1/colorway_mkg_maestro.json";
import mkg_m6 from "./usercolorways1/colorway_mkg_handa.json";
import mkg_m7 from "./usercolorways1/colorway_mkg_taiga.json";
import mkg_m8 from "./usercolorways1/colorway_mkg_bingsu.json";
import mkg_m9 from "./usercolorways1/colorway_mkg_dracula.json";
import mkg_m10 from "./usercolorways1/colorway_mkg_js.json";
import mkg_m11 from "./usercolorways1/colorway_mkg_beta.json";
import mkg_m12 from "./usercolorways1/colorway_mkg_beloved.json";
import mkg_m13 from "./usercolorways1/colorway_mkg_redalert.json";
import mkg_m14 from "./usercolorways1/colorway_mkg_art.json";
import mkg_m15 from "./usercolorways1/colorway_mkg_darling.json";
import mkg_m16 from "./usercolorways1/colorway_mkg_evil_eye.json";
import mkg_m17 from "./usercolorways1/colorway_mkg_foundation.json";
import mkg_m18 from "./usercolorways1/colorway_mkg_dualshot.json";
import mkg_m19 from "./usercolorways1/colorway_mkg_symbiote.json";
import mkg_m20 from "./usercolorways1/colorway_mkg_nachtarbeit.json";
import mkg_m21 from "./usercolorways1/colorway_mkg_dark_olivia.json";
import mkg_a1 from "./usercolorways1/colorway_mkg_arctic.json";
import mkg_a2 from "./usercolorways1/colorway_mkg_birch.json";
import mkg_a3 from "./usercolorways1/colorway_mkg_cinder.json";
import mkg_a4 from "./usercolorways1/colorway_mkg_coral.json";
import mkg_a5 from "./usercolorways1/colorway_mkg_fleuriste.json";
import mkg_a6 from "./usercolorways1/colorway_mkg_frost_witch.json";
import mkg_a7 from "./usercolorways1/colorway_mkg_galaxy.json";
import mkg_a8 from "./usercolorways1/colorway_mkg_grand_prix.json";
import mkg_a9 from "./usercolorways1/colorway_mkg_maroon.json";
import mkg_a10 from "./usercolorways1/colorway_mkg_matsu.json";
import mkg_a11 from "./usercolorways1/colorway_mkg_regal.json";
import mkg_a12 from "./usercolorways1/colorway_mkg_soyamilk.json";
import mkg_a13 from "./usercolorways1/colorway_mkg_tenshi.json";
import mkg_a14 from "./usercolorways1/colorway_mkg_vamp.json";
import mkg_a15 from "./usercolorways1/colorway_mkg_alhambra.json";
import mkg_a16 from "./usercolorways1/colorway_mkg_british_racing_green_b.json"
import mkg_a17 from "./usercolorways1/colorway_mkg_british_racing_green_r.json"
import mkg_b1 from "./usercolorways1/colorway_mkg_rome.json"
import mkg_b2 from "./usercolorways1/colorway_mkg_hanok.json"
import mkg_b3 from "./usercolorways1/colorway_mkg_barista.json"
import mkg_b4 from "./usercolorways1/colorway_mkg_apollo.json"
import mkg_b5 from "./usercolorways1/colorway_mkg_camping_burgundy.json"
import mkg_b6 from "./usercolorways1/colorway_mkg_first_love.json"
import mkg_b7 from "./usercolorways1/colorway_mkg_fishing.json"
import mkg_b8 from "./usercolorways1/colorway_mkg_Illusion.json"
import mkg_b9 from "./usercolorways1/colorway_mkg_manhattan_cafe.json"
import mkg_b10 from "./usercolorways1/colorway_mkg_marmoreal.json"
import mkg_b11 from "./usercolorways1/colorway_mkg_winterglow.json"
import mkg_c1 from "./usercolorways1/colorway_mkg_stamp.json"
import mkg_c2 from "./usercolorways1/colorway_mkg_wavez.json"
import mkg_c3 from "./usercolorways1/colorway_mkg_rudy.json"
import mkg_c4 from "./usercolorways1/colorway_mkg_merlin.json"
import mkg_c5 from "./usercolorways1/colorway_mkg_mars_green.json"
import mkg_c6 from "./usercolorways1/colorway_mkg_aegyptus.json"
import mkg_c7 from "./usercolorways1/colorway_mkg_3run.json"
import mkg_c8 from "./usercolorways1/colorway_mkg_amethyst.json"
import mkg_c9 from "./usercolorways1/colorway_mkg_amethyst_dark.json"
import mkg_c10 from "./usercolorways1/colorway_mkg_analog_dreams.json"
import mkg_c11 from "./usercolorways1/colorway_mkg_black_pink.json"
import mkg_c12 from "./usercolorways1/colorway_mkg_calm_depths.json"
import mkg_c13 from "./usercolorways1/colorway_mkg_daifuku.json"
import mkg_c14 from "./usercolorways1/colorway_mkg_deku.json"
import mkg_c15 from "./usercolorways1/colorway_mkg_dolch_reborn.json"
import mkg_c16 from "./usercolorways1/colorway_mkg_evil_dolch.json"
import mkg_c17 from "./usercolorways1/colorway_mkg_hydro.json"
import mkg_c18 from "./usercolorways1/colorway_mkg_hyperfuse.json"
import mkg_c19 from "./usercolorways1/colorway_mkg_iceberg.json"
import mkg_c20 from "./usercolorways1/colorway_mkg_lavender.json"
import mkg_c21 from "./usercolorways1/colorway_mkg_lavender_violet.json"
import mkg_d1 from "./usercolorways1/colorway_mkg_mint_chocolate.json"
import mkg_d2 from "./usercolorways1/colorway_mkg_noel.json"
import mkg_d3 from "./usercolorways1/colorway_mkg_phantom.json"
import mkg_d4 from "./usercolorways1/colorway_mkg_plum.json"
import mkg_d5 from "./usercolorways1/colorway_mkg_retro_mixed_lights.json"
import mkg_d6 from "./usercolorways1/colorway_mkg_carpenter.json"
import mkg_d7 from "./usercolorways1/colorway_mkg_panels.json"
import mkg_d8 from "./usercolorways1/colorway_mkg_spark_light.json"
import mkg_d9 from "./usercolorways1/colorway_mkg_spark.json"
import mkg_d10 from "./usercolorways1/colorway_mkg_violet_alert.json"
import mkg_f1 from "./usercolorways2/mkg_bank_account.json"
import mkg_f2 from "./usercolorways2/mkg_kabukicho.json"
import mkg_f3 from "./usercolorways2/mkg_kitsune.json"
import mkg_f4 from "./usercolorways2/mkg_love_story.json"
import mkg_f5 from "./usercolorways2/mkg_mictlan.json"
import mkg_f6 from "./usercolorways2/mkg_pelican.json"
import mkg_f7 from "./usercolorways2/mkg_poker.json"
import mkg_f8 from "./usercolorways2/mkg_ronin.json"
import mkg_f9 from "./usercolorways2/mkg_salmon.json"
import mkg_f10 from "./usercolorways2/mkg_silent_forest.json"
import mkg_f11 from "./usercolorways2/mkg_skidata.json"
import mkg_f12 from "./usercolorways2/mkg_sky_dolch.json"
import mkg_f13 from "./usercolorways2/mkg_soju.json"
import mkg_f14 from "./usercolorways2/mkg_soju_green_grape.json"
import mkg_f15 from "./usercolorways2/mkg_soju_peach.json"
import mkg_f17 from "./usercolorways2/mkg_spark_light_old.json"
import mkg_f18 from "./usercolorways2/mkg_ta.json"
import mkg_f20 from "./usercolorways2/mkg_triumph_adler.json"
import mkg_f21 from "./usercolorways2/mkg_tuzi.json"
import mkg_f22 from "./usercolorways2/mkg_vibrato.json"
import mkg_f23 from "./usercolorways2/mkg_aler.json"
import mkg_f24 from "./usercolorways2/mkg_dandy.json"
import mkg_f25 from "./usercolorways2/mkg_fairy.json"
import mkg_f26 from "./usercolorways2/mkg_mictlan_noche_negra.json"
import mkg_f27 from "./usercolorways2/mkg_olive_blanc.json"
import mkg_f28 from "./usercolorways2/mkg_retro100.json"
import mkg_f29 from "./usercolorways2/mkg_voc.json"
import mkg_f30 from "./usercolorways2/mkg_prussian_alert.json"
import mkg_f31 from "./usercolorways2/mkg_retro_dark_lights.json"
import mkg_f32 from "./usercolorways2/mkg_bubble_tea.json"
import mkg_f33 from "./usercolorways2/mkg_gladiator.json"
import mkg_f34 from "./usercolorways2/mkg_gladiator_novelty.json"
import mkg_f35 from "./usercolorways2/mkg_gelato.json"
import mkg_f36 from "./usercolorways2/mkg_analog_dreams_dark.json"
import mkg_f37 from "./usercolorways2/mkg_dreamscape.json"
import mkg_f38 from "./usercolorways2/mkg_davy_jones.json"
import mkg_f39 from "./usercolorways2/mkg_blot.json"
import mkg_f40 from "./usercolorways2/mkg_basin.json"
import mkg_f41 from "./usercolorways2/mkg_valhalla.json"

import mkg_g1 from "./usercolorways2/mkg_black_snail_alpha.json"
import mkg_g2 from "./usercolorways2/mkg_cube.json"
import mkg_g3 from "./usercolorways2/mkg_emma.json"
import mkg_g4 from "./usercolorways2/mkg_emma_base.json"
import mkg_g5 from "./usercolorways2/mkg_nori.json"
import mkg_g6 from "./usercolorways2/mkg_nori_alpha.json"
import mkg_g7 from "./usercolorways2/mkg_parcel.json"
import mkg_g8 from "./usercolorways2/mkg_poco.json"
import mkg_g9 from "./usercolorways2/mkg_sandstorm.json"
import mkg_g10 from "./usercolorways2/mkg_soul_blossom.json"
import mkg_g11 from "./usercolorways2/mkg_spark_mod.json"
import mkg_g12 from "./usercolorways2/mkg_yeeti.json"
import mkg_g13 from "./usercolorways2/mkg_olive_noir.json"
import mkg_g14 from "./usercolorways2/mkg_classic_beige.json"
import mkg_g15 from "./usercolorways2/mkg_euler.json"
import mkg_g16 from "./usercolorways2/mkg_pc_neon_lights.json"
import mkg_g17 from "./usercolorways2/mkg_rubrehose.json"

import mkg_h1 from "./usercolorways2/mkg_deepwell.json"
import mkg_h2 from "./usercolorways2/mkg_nerve_acero.json"
import mkg_h3 from "./usercolorways2/mkg_nerve_neon.json"
import mkg_h4 from "./usercolorways2/mkg_aifei_love_story.json"
import mkg_h5 from "./usercolorways2/mkg_black_snail_beige_alert.json"
import mkg_h6 from "./usercolorways2/mkg_domikey_astronaut.json"
import mkg_h7 from "./usercolorways2/mkg_delta.json"
import mkg_h8 from "./usercolorways2/mkg_delta_dark.json"

import mkg_i1 from "./usercolorways3/mkg_blue_screen.json"
import mkg_i2 from "./usercolorways3/mkg_coffee_latte.json"
import mkg_i3 from "./usercolorways3/mkg_chladni.json"
import mkg_i4 from "./usercolorways3/mkg_kura.json"
import mkg_i6 from "./usercolorways3/mkg_zx.json"
import mkg_i7 from "./usercolorways3/mkg_griseann_2.json"
import mkg_i8 from "./usercolorways3/mkg_lucy.json"
import mkg_i9 from "./usercolorways3/mkg_mystic_dao.json"
import mkg_i10 from "./usercolorways3/mkg_retro_dark_lights_r2.json"
import mkg_i11 from "./usercolorways3/mkg_tempest.json"
import mkg_i12 from "./usercolorways3/mkg_lofi.json"
import mkg_i13 from "./usercolorways3/mkg_crosshair.json"
import mkg_i14 from "./usercolorways3/mkg_cocogoat.json"
import mkg_i15 from "./usercolorways3/mkg_ascii.json"

import mkg_j1 from "./usercolorways3/mkg_3077.json"
import mkg_j2 from "./usercolorways3/mkg_3077_num.json"
import mkg_j3 from "./usercolorways3/mkg_helleborus.json"
import mkg_j4 from "./usercolorways3/mkg_sl_cipher.json"
import mkg_j5 from "./usercolorways3/mkg_soda_squid.json"
import mkg_j6 from "./usercolorways3/mkg_stargaze.json"
import mkg_j7 from "./usercolorways3/mkg_stargaze_alpha.json"
import mkg_j8 from "./usercolorways3/mkg_war_maiden.json"
import mkg_j9 from "./usercolorways3/mkg_war_maiden_band.json"
import mkg_j10 from "./usercolorways3/mkg_war_maiden_borsche.json"
import mkg_j11 from "./usercolorways3/mkg_yokai.json"
import mkg_j12 from "./usercolorways3/mkg_colorful_beige.json"
import mkg_j13 from "./usercolorways3/mkg_colorful_white.json"
import mkg_j14 from "./usercolorways3/mkg_henchman.json"
import mkg_j15 from "./usercolorways3/mkg_buff_bride.json"

import mkg_k1 from "./usercolorways3/mkg_classic1.json"
import mkg_k2 from "./usercolorways3/mkg_classic2.json"
import mkg_k3 from "./usercolorways3/mkg_classic3.json"
import mkg_k4 from "./usercolorways3/mkg_mecha_00.json"
import mkg_k5 from "./usercolorways3/mkg_mecha_00_alpha.json"
import mkg_k6 from "./usercolorways3/mkg_tribal.json"
import mkg_k7 from "./usercolorways3/mkg_twist_r2.json"
import mkg_k8 from "./usercolorways3/mkg_mw_frieren.json"
import mkg_k9 from "./usercolorways3/mkg_good_night.json"
import mkg_k11 from "./usercolorways3/mkg_my_first_joy.json"
import mkg_k12 from "./usercolorways3/mkg_nama.json"
import mkg_k13 from "./usercolorways3/mkg_pandora.json"
import mkg_k14 from "./usercolorways3/mkg_to_my_lover.json"

import mkg_l1 from "./usercolorways3/mkg_dragon_witch.json"
import mkg_l2 from "./usercolorways3/mkg_teradrive.json"

import mkg_gmk_classic_arabic from "./usercolorways4/mkg_gmk_classic_arabic.json";
import mkg_gmk_boho from "./usercolorways4/mkg_gmk_boho.json";
import mkg_gmk_moonlight from "./usercolorways4/mkg_gmk_moonlight.json";
import mkg_gmk_hazakura from "./usercolorways4/mkg_gmk_hazakura.json";
import mkg_gmk_lazurite from "./usercolorways4/mkg_gmk_lazurite.json";
import mkg_gmk_boulder from "./usercolorways4/mkg_gmk_boulder.json";
import mkg_gmk_blurple from "./usercolorways4/mkg_gmk_blurple.json";
import mkg_gmk_astral_light from "./usercolorways4/mkg_gmk_astral_light.json";
import mkg_gmk_fuji from "./usercolorways4/mkg_gmk_fuji.json";
import mkg_gmk_terror from "./usercolorways4/mkg_gmk_terror.json";
import mkg_gmk_tiramisu from "./usercolorways4/mkg_gmk_tiramisu.json";
import mkg_gmk_cream_matcha from "./usercolorways4/mkg_gmk_cream_matcha.json";
import mkg_gmk_slasher from "./usercolorways4/mkg_gmk_slasher.json";
import mkg_gmk_monarch from "./usercolorways4/mkg_gmk_monarch.json";
import mkg_gmk_tako from "./usercolorways4/mkg_gmk_tako.json";
import mkg_gmk_bordeaux from "./usercolorways4/mkg_gmk_bordeaux.json";
import mkg_gmk_earth_tones from "./usercolorways4/mkg_gmk_earth_tones.json";
import mkg_gmk_higanbana from "./usercolorways4/mkg_gmk_higanbana.json";
import mkg_gmk_moomin from "./usercolorways4/mkg_gmk_moomin.json";
import mkg_gmk_a from "./usercolorways4/mkg_gmk_a.json";
import mkg_gmk_jags from "./usercolorways4/mkg_gmk_jags.json";
import mkg_gmk_shadow from "./usercolorways4/mkg_gmk_shadow.json";
import mkg_gmk_shashin from "./usercolorways4/mkg_gmk_shashin.json";
import mkg_gmk_monochrome_r2 from "./usercolorways4/mkg_gmk_monochrome_r2.json";
import mkg_gmk_lychee from "./usercolorways4/mkg_gmk_lychee.json";
import mkg_gmk_peach_blossom from "./usercolorways4/mkg_gmk_peach_blossom.json";
import mkg_gmk_zimo from "./usercolorways4/mkg_gmk_zimo.json";
import mkg_gmk_zen_pond from "./usercolorways4/mkg_gmk_zen_pond.json";
import mkg_gmk_nightlight from "./usercolorways4/mkg_gmk_nightlight.json";
import mkg_gmk_reforged from "./usercolorways4/mkg_gmk_reforged.json";
import mkg_gmk_harvest from "./usercolorways4/mkg_gmk_harvest.json";
import mkg_gmk_firefly from "./usercolorways4/mkg_gmk_firefly.json";
import mkg_gmk_pharaoh from "./usercolorways4/mkg_gmk_pharaoh.json";
import mkg_gmk_mono_yume from "./usercolorways4/mkg_gmk_mono_yume.json";
import mkg_gmk_abyssal from "./usercolorways4/mkg_gmk_abyssal.json";
import mkg_gmk_orange_boi from "./usercolorways4/mkg_gmk_orange_boi.json";
import mkg_gmk_blossom from "./usercolorways4/mkg_gmk_blossom.json";
import mkg_gmk_manta from "./usercolorways4/mkg_gmk_manta.json";
import mkg_gmk_november_fog from "./usercolorways4/mkg_gmk_november_fog.json";
import mkg_gmk_villanelle from "./usercolorways4/mkg_gmk_villanelle.json";
import mkg_gmk_infernal from "./usercolorways4/mkg_gmk_infernal.json";
import mkg_gmk_hooty from "./usercolorways4/mkg_gmk_hooty.json";
import mkg_gmk_yutousu from "./usercolorways4/mkg_gmk_yutousu.json";
import mkg_keykobo_cherries from "./usercolorways4/mkg_keykobo_cherries.json";
import mkg_keykobo_cherries_alpha from "./usercolorways4/mkg_keykobo_cherries_alpha.json";
import mkg_keykobo_verdigris from "./usercolorways4/mkg_keykobo_verdigris.json";
import mkg_pbtfans_no_signal from "./usercolorways4/mkg_pbtfans_no_signal.json";
import mkg_gmk_sloth from "./usercolorways5/mkg_gmk_sloth.json";
import mkg_gmk_thai_tea from "./usercolorways5/mkg_gmk_thai_tea.json";
import mkg_gmk_copper from "./usercolorways5/mkg_gmk_copper.json";
import mkg_dcs_soware from "./usercolorways5/mkg_dcs_soware.json";
import {COLORWAYS_NEW} from "./output";
import mkg_gmk_dolch from "./usercolorways6/mkg_gmk_dolch.json";
import mkg_gmk_fundamentals from "./usercolorways6/mkg_gmk_fundamentals.json";
import mkg_gmk_hyperfuse_origin_r2 from "./usercolorways6/mkg_gmk_hyperfuse_origin_r2.json";
import mkg_gmk_hyperfuse_r2 from "./usercolorways6/mkg_gmk_hyperfuse_r2.json";
import mkg_gmk_mercury from "./usercolorways6/mkg_gmk_mercury.json";
import mkg_gmk_monochrome_dolch from "./usercolorways6/mkg_gmk_monochrome_dolch.json";
import mkg_gmk_norse from "./usercolorways6/mkg_gmk_norse.json";
import mkg_gmk_rubrehose from "./usercolorways6/mkg_gmk_rubrehose.json";
import mkg_gmk_thinkcaps from "./usercolorways6/mkg_gmk_thinkcaps.json";
import mkg_gmk_trader_boomer from "./usercolorways6/mkg_gmk_trader_boomer.json";
import mkg_gmk_trader_zoomer from "./usercolorways6/mkg_gmk_trader_zoomer.json";

export const USERCOLORWAYS_NEW = {
    ...COLORWAYS_NEW
}

export const USERCOLORWAYS = {
    mkg_gmk_sloth: mkg_gmk_sloth,
    mkg_gmk_thai_tea: mkg_gmk_thai_tea,
    mkg_gmk_copper: mkg_gmk_copper,
    mkg_dcs_soware: mkg_dcs_soware,
    mkg_gmk_classic_arabic: mkg_gmk_classic_arabic,
    mkg_gmk_boho: mkg_gmk_boho,
    mkg_gmk_moonlight: mkg_gmk_moonlight,
    mkg_gmk_hazakura: mkg_gmk_hazakura,
    mkg_gmk_lazurite: mkg_gmk_lazurite,
    mkg_gmk_boulder: mkg_gmk_boulder,
    mkg_gmk_blurple: mkg_gmk_blurple,
    mkg_gmk_astral_light: mkg_gmk_astral_light,
    mkg_gmk_fuji: mkg_gmk_fuji,
    mkg_gmk_terror: mkg_gmk_terror,
    mkg_gmk_tiramisu: mkg_gmk_tiramisu,
    mkg_gmk_cream_matcha: mkg_gmk_cream_matcha,
    mkg_gmk_slasher: mkg_gmk_slasher,
    mkg_gmk_monarch: mkg_gmk_monarch,
    mkg_gmk_tako: mkg_gmk_tako,
    mkg_gmk_bordeaux: mkg_gmk_bordeaux,
    mkg_gmk_earth_tones: mkg_gmk_earth_tones,
    mkg_gmk_higanbana: mkg_gmk_higanbana,
    mkg_gmk_moomin: mkg_gmk_moomin,
    mkg_gmk_a: mkg_gmk_a,
    mkg_gmk_jags: mkg_gmk_jags,
    mkg_gmk_shadow: mkg_gmk_shadow,
    mkg_gmk_shashin: mkg_gmk_shashin,
    mkg_gmk_monochrome_r2: mkg_gmk_monochrome_r2,
    mkg_gmk_lychee: mkg_gmk_lychee,
    mkg_gmk_peach_blossom: mkg_gmk_peach_blossom,
    mkg_gmk_zimo: mkg_gmk_zimo,
    mkg_gmk_zen_pond: mkg_gmk_zen_pond,
    mkg_gmk_nightlight: mkg_gmk_nightlight,
    mkg_gmk_reforged: mkg_gmk_reforged,
    mkg_gmk_harvest: mkg_gmk_harvest,
    mkg_gmk_firefly: mkg_gmk_firefly,
    mkg_gmk_pharaoh: mkg_gmk_pharaoh,
    mkg_gmk_mono_yume: mkg_gmk_mono_yume,
    mkg_gmk_abyssal: mkg_gmk_abyssal,
    mkg_gmk_orange_boi: mkg_gmk_orange_boi,
    mkg_gmk_blossom: mkg_gmk_blossom,
    mkg_gmk_manta: mkg_gmk_manta,
    mkg_gmk_november_fog: mkg_gmk_november_fog,
    mkg_gmk_villanelle: mkg_gmk_villanelle,
    mkg_gmk_infernal: mkg_gmk_infernal,
    mkg_gmk_hooty: mkg_gmk_hooty,
    mkg_gmk_yutousu: mkg_gmk_yutousu,
    mkg_keykobo_cherries: mkg_keykobo_cherries,
    mkg_keykobo_cherries_alpha: mkg_keykobo_cherries_alpha,
    mkg_keykobo_verdigris: mkg_keykobo_verdigris,
    mkg_pbtfans_no_signal: mkg_pbtfans_no_signal,
    mkg_my_first_joy: mkg_k11,
    mkg_nama: mkg_k12,
    mkg_pandora: mkg_k13,
    mkg_to_my_lover: mkg_k14,
    mkg_dragon_witch: mkg_l1,
    mkg_teradrive: mkg_l2,
    mkg_buff_bride: mkg_j15,
    mkg_classic1: mkg_k1,
    mkg_classic2: mkg_k2,
    mkg_classic3: mkg_k3,
    mkg_mecha_00: mkg_k4,
    mkg_mecha_00_alpha: mkg_k5,
    mkg_tribal: mkg_k6,
    mkg_twist_r2: mkg_k7,
    mkg_mw_frieren: mkg_k8,
    mkg_good_night: mkg_k9,
    mkg_3077: mkg_j1,
    mkg_3077_num: mkg_j2,
    mkg_helleborus: mkg_j3,
    mkg_sl_cipher: mkg_j4,
    mkg_soda_squid: mkg_j5,
    mkg_stargaze: mkg_j6,
    mkg_stargaze_alpha: mkg_j7,
    mkg_war_maiden: mkg_j8,
    mkg_war_maiden_band: mkg_j9,
    mkg_war_maiden_borsche: mkg_j10,
    mkg_yokai: mkg_j11,
    mkg_colorful_beige: mkg_j12,
    mkg_colorful_white: mkg_j13,
    mkg_henckman: mkg_j14,
    mkg_blue_screen: mkg_i1,
    mkg_coffee_latte: mkg_i2,
    mkg_chladni: mkg_i3,
    mkg_kura: mkg_i4,
    mkg_zx: mkg_i6,
    mkg_griseann_2: mkg_i7,
    mkg_lucy: mkg_i8,
    mkg_mystic_dao: mkg_i9,
    mkg_retro_dark_lights_r2: mkg_i10,
    mkg_tempest: mkg_i11,
    mkg_lofi: mkg_i12,
    mkg_crosshair: mkg_i13,
    mkg_cocogoat: mkg_i14,
    mkg_ascii: mkg_i15,
    mkg_beloved: mkg_m12,
    mkg_rubrehose: mkg_g17,
    mkg_deepwell: mkg_h1,
    mkg_nerve_acero: mkg_h2,
    mkg_nerve_neon: mkg_h3,
    mkg_aifei_love_story: mkg_h4,
    mkg_black_snail_beige_alert: mkg_h5,
    mkg_domikey_astronaut: mkg_h6,
    mkg_delta: mkg_h7,
    mkg_delta_dark: mkg_h8,
    //APPEND
    mkg_ntd: mkg_ntd,
    mkg_masterpiece: mkg_m1,
    mkg_kaiju: mkg_m2,
    mkg_sang: mkg_m3,
    mkg_maestro: mkg_m4,
    mkg_handa: mkg_m6,
    mkg_taiga: mkg_m7,
    mkg_bingsu: mkg_m8,
    mkg_dracula: mkg_m9,
    mkg_js: mkg_m10,
    mkg_beta: mkg_m11,
    mkg_red_alert: mkg_m13,
    mkg_art: mkg_m14,
    mkg_darling: mkg_m15,
    mkg_evil_eye: mkg_m16,
    mkg_foundation: mkg_m17,
    mkg_dualshot: mkg_m18,
    mkg_symbiote: mkg_m19,
    mkg_nachtarbeit: mkg_m20,
    mkg_dark_olivia: mkg_m21,
    mkg_arctic: mkg_a1,
    mkg_birch: mkg_a2,
    mkg_cinder: mkg_a3,
    mkg_coral: mkg_a4,
    mkg_fleuriste: mkg_a5,
    mkg_frost_witch: mkg_a6,
    mkg_galaxy: mkg_a7,
    mkg_grand_prix: mkg_a8,
    mkg_maroon: mkg_a9,
    mkg_matsu: mkg_a10,
    mkg_regal: mkg_a11,
    mkg_soyamilk: mkg_a12,
    mkg_tenshi: mkg_a13,
    mkg_vamp: mkg_a14,
    mkg_alhambra: mkg_a15,
    mkg_british_racing_green_b: mkg_a16,
    mkg_british_racing_green_r: mkg_a17,
    mkg_rome: mkg_b1,
    mkg_hanok: mkg_b2,
    mkg_barista: mkg_b3,
    mkg_apollo: mkg_b4,
    mkg_camping_burgundy: mkg_b5,
    mkg_first_love: mkg_b6,
    mkg_fishing: mkg_b7,
    mkg_illusion: mkg_b8,
    mkg_manhattan_cafe: mkg_b9,
    mkg_marmoreal: mkg_b10,
    mkg_winterglow: mkg_b11,
    mkg_stamp: mkg_c1,
    mkg_wavez: mkg_c2,
    mkg_rudy: mkg_c3,
    mkg_merlin: mkg_c4,
    mkg_mars_green: mkg_c5,
    mkg_aegyptus: mkg_c6,
    mkg_3run: mkg_c7,
    mkg_amethyst: mkg_c8,
    mkg_amethyst_dark: mkg_c9,
    mkg_analog_dreams: mkg_c10,
    mkg_black_pink: mkg_c11,
    mkg_calm_depths: mkg_c12,
    mkg_daifuku: mkg_c13,
    mkg_deku: mkg_c14,
    mkg_dolch_reborn: mkg_c15,
    mkg_evil_dolch: mkg_c16,
    mkg_hydro: mkg_c17,
    mkg_hyperfuse: mkg_c18,
    mkg_iceberg: mkg_c19,
    mkg_lavender: mkg_c20,
    mkg_lavender_violet: mkg_c21,
    mkg_mint_chocolate: mkg_d1,
    mkg_noel: mkg_d2,
    mkg_phantom: mkg_d3,
    mkg_plum: mkg_d4,
    mkg_retro_mixed_lights: mkg_d5,
    mkg_carpenter: mkg_d6,
    mkg_panels: mkg_d7,
    mkg_spark_light: mkg_d8,
    mkg_spark: mkg_d9,
    mkg_violet_alert: mkg_d10,

    mkg_bank_account: mkg_f1,
    mkg_kabukicho: mkg_f2,
    mkg_kitsune: mkg_f3,
    mkg_love_story: mkg_f4,
    mkg_mictlan: mkg_f5,
    mkg_pelican: mkg_f6,
    mkg_poker: mkg_f7,
    mkg_ronin: mkg_f8,
    mkg_salmon: mkg_f9,
    mkg_silent_forest: mkg_f10,
    mkg_skidata: mkg_f11,
    mkg_skyt_dolch: mkg_f12,
    mkg_soju: mkg_f13,
    mkg_soju_green_grape: mkg_f14,
    mkg_soju_peach: mkg_f15,
    mkg_spark_light_old: mkg_f17,
    mkg_ta: mkg_f18,
    mkg_triumph_adler: mkg_f20,
    mkg_tuzi: mkg_f21,
    mkg_vibrato: mkg_f22,
    mkg_aler: mkg_f23,
    mkg_dandy: mkg_f24,
    mkg_fairy: mkg_f25,
    mkg_mictlan_noche_negra: mkg_f26,
    mkg_olive_blanc: mkg_f27,
    mkg_retro100: mkg_f28,
    mkg_voc: mkg_f29,
    mkg_prussian_alert: mkg_f30,
    mkg_retro_dark_lights: mkg_f31,
    mkg_bubble_tea: mkg_f32,
    mkg_gladiator: mkg_f33,
    mkg_gladiator_novelty: mkg_f34,
    mkg_gelato: mkg_f35,
    mkg_analog_dreams_dark: mkg_f36,
    mkg_dreamscape: mkg_f37,
    mkg_davy_jones: mkg_f38,
    mkg_blot: mkg_f39,
    mkg_basin: mkg_f40,
    mkg_valhalla: mkg_f41,
    mkg_black_snail_alpha: mkg_g1,
    mkg_cube: mkg_g2,
    mkg_emma: mkg_g3,
    mkg_emma_base: mkg_g4,
    mkg_nori: mkg_g5,
    mkg_nori_alpha: mkg_g6,
    mkg_parcel: mkg_g7,
    mkg_poco: mkg_g8,
    mkg_sandstorm: mkg_g9,
    mkg_soul_blossom: mkg_g10,
    mkg_spark_mod: mkg_g11,
    mkg_yeeti: mkg_g12,
    mkg_olive_noir: mkg_g13,
    mkg_classic_beige: mkg_g14,
    mkg_euler: mkg_g15,
    mkg_pc_neon_lights: mkg_g16,

    mkg_gmk_dolch: mkg_gmk_dolch,
    mkg_gmk_fundamentals: mkg_gmk_fundamentals,
    mkg_gmk_hyperfuse_origin_r2: mkg_gmk_hyperfuse_origin_r2,
    mkg_gmk_hyperfuse_r2: mkg_gmk_hyperfuse_r2,
    mkg_gmk_mercury: mkg_gmk_mercury,
    mkg_gmk_monochrome_dolch: mkg_gmk_monochrome_dolch,
    mkg_gmk_norse: mkg_gmk_norse,
    mkg_gmk_rubrehose: mkg_gmk_rubrehose,
    mkg_gmk_thinkcaps: mkg_gmk_thinkcaps,
    mkg_gmk_trader_boomer: mkg_gmk_trader_boomer,
    mkg_gmk_trader_zoomer: mkg_gmk_trader_zoomer
};